import defaults from '../defaults';
import withDevtools from '../enhancers/devtools';
import applyMiddleware from '../middlewares';
import type { StoreState } from '../types';
import schedule from '../utils/schedule';

type Listener<TState> = (state: TState) => void;

function createStoreState<TState>(key: string, initialState: TState): StoreState<TState> {
	const listeners = new Set<Listener<TState>>();
	let currentState: Partial<TState> = initialState;
	const storeState: StoreState<TState> = {
		key,
		getState() {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- ERROR TO BE FIXED
			return currentState as TState;
		},
		setState(nextState: Partial<TState>) {
			currentState = nextState;
			// Instead of notifying all handlers immediately, we wait next tick
			// so multiple actions affecting the same store gets combined
			schedule(storeState.notify);
		},
		resetState() {
			storeState.setState(initialState);
		},
		notify() {
			for (const listener of listeners) {
				listener(storeState.getState());
			}
		},
		subscribe(listener: Listener<TState>) {
			listeners.add(listener);
			return function unsubscribe() {
				listeners.delete(listener);
			};
		},
		listeners() {
			return listeners;
		},
		// Added to satisfy StoreState<TState> type
		// eslint-disable-next-line @typescript-eslint/no-empty-function -- ERROR TO BE FIXED
		mutator: () => {},
	};
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- ERROR TO BE FIXED
	storeState.mutator = applyMiddleware(
		storeState,
		defaults.middlewares,
	) as StoreState<TState>['setState'];
	return storeState;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- ERROR TO BE FIXED
export default withDevtools(createStoreState) as typeof createStoreState;
