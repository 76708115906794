import type {
	Action,
	ActionThunk,
	BaseContainerProps,
	GenericContainerComponent,
	Store,
} from '../types';
import hash from '../utils/hash';

function createKey<TState, TActions extends Record<string, ActionThunk<TState, TActions>>>(
	initialState: TState,
	actions: TActions,
	name: string,
) {
	const src = !name ? Object.keys(actions).reduce((acc, k) => acc + actions[k].toString(), '') : '';
	return [name, hash(src + JSON.stringify(initialState))].filter(Boolean).join('__');
}

export function createStore<
	TState extends object,
	TActions extends Record<string, ActionThunk<TState, TActions>>,
	TContainerProps = unknown,
>(
	config:
		| {
				initialState: TState;
				actions: TActions;
				name?: string;
				containedBy?: never;
				handlers?: never;
		  }
		| {
				initialState: TState;
				actions: TActions;
				name?: string;
				containedBy: GenericContainerComponent<TContainerProps>;
				handlers?: {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					onInit?: () => Action<TState, TContainerProps, any>;
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					onUpdate?: () => Action<TState, TContainerProps, any>;
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					onDestroy?: () => Action<TState, TContainerProps, any>;
					onContainerUpdate?: (
						nextProps: BaseContainerProps & TContainerProps,
						prevProps: BaseContainerProps & TContainerProps,
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
					) => Action<TState, TContainerProps, any>;
				};
		  },
): Store<TState, TActions>;

export function createStore({
	name = '',
	initialState,
	actions,
	containedBy,
	handlers = {},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: any): any {
	let key: string;
	return {
		get key() {
			// lazy evaluate key on first access
			// eslint-disable-next-line no-return-assign
			return key || (key = createKey(initialState, actions, name));
		},
		initialState,
		actions,
		containedBy,
		handlers,
	};
}
