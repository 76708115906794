import defaults from '../defaults';
import { batch } from './batched-updates';
import supports from './supported-features';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const QUEUE: any[] = [];
let scheduled: unknown;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function schedule(fn: any) {
	// if we are in node/tests or feature disabled, make updates sync batched
	if (!defaults.batchUpdates || !supports.scheduling()) return batch(() => fn());

	// Add to queue if not already there
	// so we avoid multiple notifications to same store listeners
	if (!QUEUE.includes(fn)) QUEUE.push(fn);

	// if something already started schedule, skip
	if (scheduled) return;
	scheduled = batch(() => {
		let queueFn;
		// eslint-disable-next-line no-cond-assign
		while ((queueFn = QUEUE.shift())) queueFn();
		scheduled = null;
	});
}
