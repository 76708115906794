import flatten from 'lodash/flatten';
import isUndefined from 'lodash/isUndefined';
import last from 'lodash/last';
import merge from 'lodash/merge';
import omitBy from 'lodash/omitBy';
import uniq from 'lodash/uniq';
import type { AnalyticsEvent, AnalyticsAttributes, AnalyticsAttributesUntyped } from '../types';

/**
 * This library is the only place where you'd use raw analytics from atlaskit
 */

type Action = string | undefined;
type ActionSubject = string | undefined;
type ActionSubjectId = string;
export type AnalyticsEventOverrides = {
	actionSubject: ActionSubject;
	action: Action;
	actionSubjectId: ActionSubjectId | undefined;
	attributes: AnalyticsAttributes | undefined;
};
type EventOverride = {
	action: string;
	actionSubject: string;
	actionSubjectId: string; // TODO actionSubjectId?: string, attributes?: AnalyticsAttributes. Update isEventOverride to check only 2 properties
	attributes: AnalyticsAttributes;
};

// EventOverrides is indented to expanded on in the future to allow different types of overrides.
type EventOverrides = EventOverride;
export type ActionSubjectAndAction = `${string} ${string}`;
export type RestArguments =
	| []
	// TODO uncomment and remove usages of `| ActionSubjectId`; update places that violate it
	| [ActionSubjectAndAction | ActionSubjectId]
	| [ActionSubjectAndAction, ActionSubjectId]
	| [EventOverrides]
	| [AnalyticsAttributesUntyped]
	// TODO uncomment and remove usages of `| ActionSubjectId`; update places that violate it
	| [ActionSubjectAndAction | ActionSubjectId, AnalyticsAttributesUntyped]
	| [ActionSubjectAndAction, ActionSubjectId, AnalyticsAttributesUntyped];

// TODO remove `| ActionSubjectId` as a type option, from the actionSubjectAndAction, As the usage is deprecated
/**
 * @param string - string with format 'actionSubject action' or 'actionSubjectId'. It is recommended to supply an EventOverrides object instead of actionSubjectId.
 */
const extractDataFromStringAttribute = (
	actionSubjectAndAction: ActionSubjectAndAction | ActionSubjectId,
) => {
	const arr = actionSubjectAndAction.split(' ');
	switch (arr.length) {
		/**
		 * @deprecated We no longer support this case as it leads to confusion with how the analytics API should be used.
		 * @see https://hello.atlassian.net/wiki/spaces/TGR/pages/2922733949
		 */
		case 1:
			return {
				actionSubject: undefined,
				action: undefined,
				actionSubjectId: arr[0],
				attributes: undefined,
			};
		case 2:
			return {
				actionSubject: arr[0],
				action: arr[1],
				actionSubjectId: undefined,
				attributes: undefined,
			};
		default:
			throw Error('Event of this type is not supported');
	}
};
/**
 * Determines if the provided object is an instance of EventOverride by checking
 * for the presence of specific properties. This function is useful for type
 * guarding in contexts where the object's type might be ambiguous.
 */
export const isEventOverride = (
	obj: AnalyticsAttributes | EventOverrides,
): obj is EventOverride => {
	const keysCount = Object.keys(obj).length;
	if (keysCount === 4) {
		return (
			'actionSubjectId' in obj && 'attributes' in obj && 'action' in obj && 'actionSubject' in obj
		);
	}
	return false;
};
/**
 * @param one - can be either string with format 'actionSubject action', AnalyticsAttributes or EventOverrides.
 * @example
 * ```ts
 * extractDataFromOneAttribute('button clicked') // { actionSubject: 'button', action: 'clicked', actionSubjectId: undefined, attributes: undefined }
 * extractDataFromOneAttribute({ foo: 'bar' }) // { actionSubject: undefined, action: undefined, actionSubjectId: undefined, attributes: { foo: 'bar' } }
 * extractDataFromOneAttribute({
 *  action: 'clicked',
 *  actionSubject: 'button',
 *  actionSubjectId: 'foo',
 *  attributes: { foo: 'bar' }
 * }) // { actionSubject: 'button', action: 'clicked', actionSubjectId: 'foo', attributes: { foo: 'bar' } }
 * ```
 */
const extractDataFromOneAttribute = (
	one: ActionSubjectAndAction | ActionSubjectId | AnalyticsAttributes | EventOverrides,
) => {
	if (typeof one === 'string') {
		return extractDataFromStringAttribute(one);
	}
	if (isEventOverride(one)) {
		return one;
	}
	return {
		actionSubject: undefined,
		action: undefined,
		actionSubjectId: undefined,
		attributes: one,
	};
};
const extractDataFromTwoAttributes = (
	actionSubjectAndAction: ActionSubjectAndAction | ActionSubjectId,
	actionSubjectIdOrAttributes: ActionSubjectId | AnalyticsAttributes,
) => {
	if (typeof actionSubjectIdOrAttributes === 'string') {
		const { action, actionSubject } = extractDataFromStringAttribute(actionSubjectAndAction);
		const actionSubjectId = actionSubjectIdOrAttributes;
		return {
			actionSubject,
			action,
			actionSubjectId,
			attributes: undefined,
		};
	}
	const { action, actionSubject, actionSubjectId } =
		extractDataFromStringAttribute(actionSubjectAndAction);
	return {
		actionSubject,
		action,
		actionSubjectId,
		attributes: actionSubjectIdOrAttributes,
	};
};
const extractDataFromThreeAttributes = (
	actionSubjectAndAction: ActionSubjectAndAction,
	actionSubjectId: ActionSubjectId,
	attributes: AnalyticsAttributes,
) => {
	const { action, actionSubject } = extractDataFromStringAttribute(actionSubjectAndAction);
	return {
		actionSubject,
		action,
		actionSubjectId,
		attributes,
	};
};
const extractFromContext = (event: AnalyticsEvent, property: string) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	event.context.map((item: Record<string, any>) => item[property]);
/**
 * Generates a comprehensive object for analytics event overrides based on supplied arguments.
 * Designed to adapt to various combinations of input parameters, it ensures meaningful event
 * data is always constructed, while strictly preventing the creation of undefined or empty events.
 */
export const getSubjectsFromArguments = (...attrs: RestArguments): AnalyticsEventOverrides => {
	if (!attrs || !attrs.length) {
		return {
			action: undefined,
			actionSubject: undefined,
			actionSubjectId: undefined,
			attributes: undefined,
		};
	}
	switch (attrs.length) {
		case 1:
			return extractDataFromOneAttribute(attrs[0]);
		case 2:
			return extractDataFromTwoAttributes(attrs[0], attrs[1]);
		case 3:
			return extractDataFromThreeAttributes(attrs[0], attrs[1], attrs[2]);
		default:
			throw Error('Can not fire an empty event');
	}
};
export const getAnalyticsType = (event: AnalyticsEvent) => event.payload.analyticsType;
export const getAttributes = (event: AnalyticsEvent) => {
	const contextAttributes = extractFromContext(event, 'attributes');
	const sources = extractFromContext(event, 'source');
	const payloadAttributes = event.payload.attributes;
	const allAttributes = merge(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		contextAttributes.reduce<Record<string, any>>(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(result: Record<string, any>, extraAttributes: Record<string, any>) =>
				merge(result, extraAttributes),
			{},
		),
		payloadAttributes,
	);
	const namespaces = sources.filter(Boolean).join('.');
	return omitBy(
		{
			...allAttributes,
			namespaces,
		},
		isUndefined,
	);
};
export const getSource = (event: AnalyticsEvent): string | undefined =>
	last(extractFromContext(event, 'source').filter(Boolean));
export const getActionSubject = (event: AnalyticsEvent) => {
	const { actionSubject } = event.payload;
	if (actionSubject) {
		return actionSubject;
	}
	const component =
		last(extractFromContext(event, 'component').filter(Boolean)) ||
		last(extractFromContext(event, 'componentName').filter(Boolean));
	return component;
};
export const getAction = (event: AnalyticsEvent) => event.payload.action;
export const getActionSubjectId = (event: AnalyticsEvent) => event.payload.actionSubjectId;
export const getContainerId = (event: AnalyticsEvent) =>
	last(extractFromContext(event, 'containerId').filter(Boolean));
export const getContainerType = (event: AnalyticsEvent) =>
	last(extractFromContext(event, 'containerType').filter(Boolean));
export const getObjectId = (event: AnalyticsEvent) =>
	last(extractFromContext(event, 'objectId').filter(Boolean));
export const getObjectType = (event: AnalyticsEvent) =>
	last(extractFromContext(event, 'objectType').filter(Boolean));
export const getTags = (event: AnalyticsEvent) => {
	const tags = uniq(flatten(extractFromContext(event, 'tags'))).filter(Boolean);
	return tags.length ? tags : undefined;
};
export const getContainers = (event: AnalyticsEvent) => {
	const contextContainers = extractFromContext(event, 'containers');
	const payloadContainers = event.payload.containers;
	const allContainers = merge(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		contextContainers.reduce<Record<string, any>>(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(result: Record<string, any>, extraContainers: Record<string, any>) =>
				merge(result, extraContainers),
			{},
		),
		payloadContainers,
	);
	const finalContainers = omitBy(allContainers, isUndefined);
	return Object.keys(finalContainers).length > 0 ? finalContainers : undefined;
};
