import memoize from './memoize';

type InputSelector<ST, PR, SE> = (state: ST, props: PR) => SE;

export type OutputSelector<ST, PR, SE> = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(state: ST, props: PR, ...rest: any[]): SE;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	resultFunc(...args: any[]): SE;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	dependencies: any[];
};

export function createSelector<ST, PR = void, SE = void, T1 = void>(
	selectors: [InputSelector<ST, PR, T1>],
	resultFn: (arg1: T1) => SE,
): OutputSelector<ST, PR, SE>;
export function createSelector<ST, PR = void, SE = void, T1 = void>(
	selector1: InputSelector<ST, PR, T1>,
	resultFn: (arg1: T1) => SE,
): OutputSelector<ST, PR, SE>;
export function createSelector<ST, PR = void, SE = void, T1 = void, T2 = void>(
	selector1: InputSelector<ST, PR, T1>,
	selector2: InputSelector<ST, PR, T2>,
	resultFn: (arg1: T1, arg2: T2) => SE,
): OutputSelector<ST, PR, SE>;
export function createSelector<ST, PR = void, SE = void, T1 = void, T2 = void>(
	selectors: [InputSelector<ST, PR, T1>, InputSelector<ST, PR, T2>],
	resultFn: (arg1: T1, arg2: T2) => SE,
): OutputSelector<ST, PR, SE>;
export function createSelector<ST, PR = void, SE = void, T1 = void, T2 = void, T3 = void>(
	selector1: InputSelector<ST, PR, T1>,
	selector2: InputSelector<ST, PR, T2>,
	selector3: InputSelector<ST, PR, T3>,
	resultFn: (arg1: T1, arg2: T2, arg3: T3) => SE,
): OutputSelector<ST, PR, SE>;
export function createSelector<ST, PR = void, SE = void, T1 = void, T2 = void, T3 = void>(
	selectors: [InputSelector<ST, PR, T1>, InputSelector<ST, PR, T2>, InputSelector<ST, PR, T3>],
	resultFn: (arg1: T1, arg2: T2, arg3: T3) => SE,
): OutputSelector<ST, PR, SE>;
export function createSelector<
	ST,
	PR = void,
	SE = void,
	T1 = void,
	T2 = void,
	T3 = void,
	T4 = void,
>(
	selector1: InputSelector<ST, PR, T1>,
	selector2: InputSelector<ST, PR, T2>,
	selector3: InputSelector<ST, PR, T3>,
	selector4: InputSelector<ST, PR, T4>,
	resultFn: (arg1: T1, arg2: T2, arg3: T3, arg4: T4) => SE,
): OutputSelector<ST, PR, SE>;
export function createSelector<
	ST,
	PR = void,
	SE = void,
	T1 = void,
	T2 = void,
	T3 = void,
	T4 = void,
>(
	selectors: [
		InputSelector<ST, PR, T1>,
		InputSelector<ST, PR, T2>,
		InputSelector<ST, PR, T3>,
		InputSelector<ST, PR, T4>,
	],
	resultFn: (arg1: T1, arg2: T2, arg3: T3, arg4: T4) => SE,
): OutputSelector<ST, PR, SE>;
export function createSelector<
	ST,
	PR = void,
	SE = void,
	T1 = void,
	T2 = void,
	T3 = void,
	T4 = void,
	T5 = void,
>(
	selector1: InputSelector<ST, PR, T1>,
	selector2: InputSelector<ST, PR, T2>,
	selector3: InputSelector<ST, PR, T3>,
	selector4: InputSelector<ST, PR, T4>,
	selector5: InputSelector<ST, PR, T5>,
	resultFn: (arg1: T1, arg2: T2, arg3: T3, arg4: T4, arg5: T5) => SE,
): OutputSelector<ST, PR, SE>;
export function createSelector<
	ST,
	PR = void,
	SE = void,
	T1 = void,
	T2 = void,
	T3 = void,
	T4 = void,
	T5 = void,
>(
	selectors: [
		InputSelector<ST, PR, T1>,
		InputSelector<ST, PR, T2>,
		InputSelector<ST, PR, T3>,
		InputSelector<ST, PR, T4>,
		InputSelector<ST, PR, T5>,
	],
	resultFn: (arg1: T1, arg2: T2, arg3: T3, arg4: T4, arg5: T5) => SE,
): OutputSelector<ST, PR, SE>;
export function createSelector<
	ST,
	PR = void,
	SE = void,
	T1 = void,
	T2 = void,
	T3 = void,
	T4 = void,
	T5 = void,
	T6 = void,
>(
	selector1: InputSelector<ST, PR, T1>,
	selector2: InputSelector<ST, PR, T2>,
	selector3: InputSelector<ST, PR, T3>,
	selector4: InputSelector<ST, PR, T4>,
	selector5: InputSelector<ST, PR, T5>,
	selector6: InputSelector<ST, PR, T6>,
	resultFn: (arg1: T1, arg2: T2, arg3: T3, arg4: T4, arg5: T5, arg6: T6) => SE,
): OutputSelector<ST, PR, SE>;
export function createSelector<
	ST,
	PR = void,
	SE = void,
	T1 = void,
	T2 = void,
	T3 = void,
	T4 = void,
	T5 = void,
	T6 = void,
>(
	selectors: [
		InputSelector<ST, PR, T1>,
		InputSelector<ST, PR, T2>,
		InputSelector<ST, PR, T3>,
		InputSelector<ST, PR, T4>,
		InputSelector<ST, PR, T5>,
		InputSelector<ST, PR, T6>,
	],
	resultFn: (arg1: T1, arg2: T2, arg3: T3, arg4: T4, arg5: T5, arg6: T6) => SE,
): OutputSelector<ST, PR, SE>;

// customised version of reselect
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createSelector(...funcs: any[]): any {
	const resultFunc = funcs.pop();
	const dependencies = Array.isArray(funcs[0]) ? funcs[0] : funcs;

	const memoizedResultFunc = memoize(function () {
		// eslint-disable-next-line prefer-spread, prefer-rest-params -- ERROR TO BE FIXED
		return resultFunc.apply(null, arguments);
	});

	// this memoisation is optimised for 2 arguments (state, props)
	const selector = memoize(function () {
		// calculate all dependencies results
		const params = [];
		for (let i = 0; i < dependencies.length; i++) {
			// eslint-disable-next-line prefer-rest-params -- ERROR TO BE FIXED
			params.push(dependencies[i].apply(null, arguments));
		}
		// then call the final func with all them as arguments
		// eslint-disable-next-line prefer-spread -- ERROR TO BE FIXED
		return memoizedResultFunc.apply(null, params);
	}, true);

	// expose so we can create per scope selectors
	// API compatible with reselect@^4
	selector.resultFunc = resultFunc;
	selector.dependencies = dependencies;
	return selector;
}
