// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import {
	unstable_scheduleCallback as scheduleCallback,
	unstable_ImmediatePriority as ImmediatePriority,
} from 'scheduler';
import defaults from '../defaults';
import supports from './supported-features';

let isInsideBatchedSchedule = false;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function batch(fn: () => any) {
	// if we are in node/tests or nested schedule
	if (!defaults.batchUpdates || !supports.scheduling() || isInsideBatchedSchedule) {
		return unstable_batchedUpdates(fn);
	}

	isInsideBatchedSchedule = true;
	// Use ImmediatePriority as it has -1ms timeout
	// https://github.com/facebook/react/blob/main/packages/scheduler/src/forks/Scheduler.js#L65
	return scheduleCallback(ImmediatePriority, () => {
		unstable_batchedUpdates(fn);
		isInsideBatchedSchedule = false;
	});
}
