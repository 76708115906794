import urlParse from 'url-parse';
import defaultMappings, { PARSE_ERROR_FALLBACK_URL } from './mappings';
import type { Mappings } from './types';

const applyMatchers = (url: string, mappings: Mappings): string => {
	for (let i = 0; i < mappings.length; i += 1) {
		const mapping = mappings[i];
		if (!mapping.matcher) {
			return mapping.transformer;
		}

		const match = url.match(mapping.matcher);
		if (match) {
			if (typeof mapping.transformer === 'string') {
				if (match.length > 1) {
					throw new Error('Grouped matcher requires a match => string transformer');
				}
				return mapping.transformer;
			}

			if (match.length === 1) {
				throw new Error('Basic matcher only supports a string transformer');
			}
			return mapping.transformer(match);
		}
	}
	throw new Error('No URL mappers matched! Expected a default matcher.');
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (url: string, mappings: Mappings = defaultMappings) => {
	let parsedUrl;
	try {
		// This is a little bit of a hack, it's really hard to actually get `url-parse` to throw an
		// error, so instead we set the default base URL to be the fallback - if parsing fails the
		// fallback URL will be the parsed URL
		parsedUrl = urlParse(url, PARSE_ERROR_FALLBACK_URL);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (ex: any) {
		// we can't log the original URL or message because we could end up with UGC, so we return a
		// safe fallback URL so we don't lose the error
		return PARSE_ERROR_FALLBACK_URL;
	}

	const partialUrl = `${parsedUrl.pathname}${parsedUrl.query}`;

	const result = applyMatchers(partialUrl, mappings);

	// For the purposes of normalisation, we assume we're only returning a path im the normalised URL
	return `${parsedUrl.protocol}//${parsedUrl.host}${result}`;
};
