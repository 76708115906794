import isNil from 'lodash/isNil';
import SENTRY_SAMPLING_CONFIG, { type SamplingConfig } from './sampling-config';

const checkForRuleMatch = (config: SamplingConfig, message: string, url?: string) => {
	const { matcher, route } = config;

	// Rule #1: no regex match on message
	if (!matcher.test(message || '')) {
		return false;
	}

	// Rule #2: If the config has a route specific condition
	if (route !== undefined) {
		// the sampling config requires the error originates from a certain route to be limited
		const matchesRoute = Boolean(url !== undefined && url.endsWith(route));

		if (!matchesRoute) {
			return false;
		}
	}

	return true;
};

// The Math.random produces a 0-1 number then multiplied by samplingRate
const shouldLimit = (samplingRate: number) => Math.floor(Math.random() * samplingRate) !== 0;

// Use a builder function so we can easily mock the rule set
export const buildLimitCaptureExceptionFunction =
	(ruleSet: SamplingConfig[], shouldLimitFn: (rate: number) => boolean) =>
	(message: string, url?: string) => {
		// Find the matching config and store the rate, otherwise null
		const rule = ruleSet.find((config) => checkForRuleMatch(config, message, url));
		const samplingRate = !isNil(rule) ? rule.samplingRate : null;

		return {
			limitCaptureException: !isNil(samplingRate) ? shouldLimitFn(samplingRate) : false,
			samplingRate,
		};
	};

export default buildLimitCaptureExceptionFunction(SENTRY_SAMPLING_CONFIG, shouldLimit);
