import {
	type ProjectType,
	CORE_PROJECT,
	SOFTWARE_PROJECT,
	SERVICE_DESK_PROJECT,
	CUSTOMER_SERVICE_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types';

export const JIRA_PORTFOLIO = 'JIRA_PORTFOLIO' as const;
export const JIRA_SERVICE_DESK = 'JIRA_SERVICE_DESK' as const;
export const JIRA_CUSTOMER_SERVICE = 'JIRA_CUSTOMER_SERVICE' as const;
export const JIRA_SOFTWARE = 'JIRA_SOFTWARE' as const;
export const JIRA_CORE = 'JIRA_CORE' as const;
export const JIRA_PRODUCT_DISCOVERY = 'JIRA_PRODUCT_DISCOVERY' as const;

type ProjectApplication =
	| typeof JIRA_SERVICE_DESK
	| typeof JIRA_CUSTOMER_SERVICE
	| typeof JIRA_SOFTWARE
	| typeof JIRA_CORE
	| typeof JIRA_PRODUCT_DISCOVERY;

export type Application = ProjectApplication | typeof JIRA_PORTFOLIO;

export const getApplicationForProject = (projectType: ProjectType): ProjectApplication => {
	switch (projectType) {
		case CORE_PROJECT:
			return JIRA_CORE;
		case PRODUCT_DISCOVERY_PROJECT:
			return JIRA_PRODUCT_DISCOVERY;
		case SOFTWARE_PROJECT:
			return JIRA_SOFTWARE;
		case SERVICE_DESK_PROJECT:
			return JIRA_SERVICE_DESK;
		case CUSTOMER_SERVICE_PROJECT:
			return JIRA_CUSTOMER_SERVICE;
		default:
			throw new Error(`Unrecognised project type: ${projectType}`);
	}
};

// Jira Application Key type
export type JiraApplicationKey = ProjectApplication;

export const getApplicationKeyForProject = (projectType: ProjectType): JiraApplicationKey => {
	switch (projectType) {
		case CORE_PROJECT:
			return JIRA_CORE;
		case PRODUCT_DISCOVERY_PROJECT:
			return JIRA_PRODUCT_DISCOVERY;
		case SOFTWARE_PROJECT:
			return JIRA_SOFTWARE;
		case SERVICE_DESK_PROJECT:
			return JIRA_SERVICE_DESK;
		case CUSTOMER_SERVICE_PROJECT:
			return JIRA_CUSTOMER_SERVICE;
		default:
			throw new Error(`Unrecognised project type: ${projectType}`);
	}
};
