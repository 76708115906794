import type { MetaGetter } from '@atlassian/jira-get-meta';
import {
	CORE,
	SOFTWARE,
	PRODUCT_DISCOVERY,
	SERVICE_DESK,
	type ProductPermissionGates,
} from '@atlassian/jira-shared-types';

type ProductPermissionGatesRaw = {
	[applicationName: string]: boolean;
};

// Fallback to true(not allowed) if product does not exist. This makes sense, because the tenant has no product.
// If user tries to somehow access the feature they are not supposed to access, it should be blocked.
const parseProductPermissionGates = (
	productPermissionGatesRaw: ProductPermissionGatesRaw,
): ProductPermissionGates => ({
	core:
		typeof productPermissionGatesRaw[CORE] === 'boolean' ? productPermissionGatesRaw[CORE] : true,
	software:
		typeof productPermissionGatesRaw[SOFTWARE] === 'boolean'
			? productPermissionGatesRaw[SOFTWARE]
			: true,
	serviceDesk:
		typeof productPermissionGatesRaw[SERVICE_DESK] === 'boolean'
			? productPermissionGatesRaw[SERVICE_DESK]
			: true,
	productDiscovery:
		typeof productPermissionGatesRaw[PRODUCT_DISCOVERY] === 'boolean'
			? productPermissionGatesRaw[PRODUCT_DISCOVERY]
			: true,
});

const defaultProductPermissionGates: ProductPermissionGates = {
	core: true,
	software: true,
	serviceDesk: true,
	productDiscovery: true,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (getMetaImpl: MetaGetter): ProductPermissionGates => {
	try {
		const productPermissionGatesRaw = JSON.parse(
			getMetaImpl('ajs-product-permission-gates') || '{}',
		);
		return parseProductPermissionGates(productPermissionGatesRaw);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		// eslint-disable-next-line no-console
		console.warn(`Error parsing product permission gates from tenant context: ${error}`);
		return defaultProductPermissionGates;
	}
};
