import defaults from '../defaults';
import type {
	Action,
	ActionThunk,
	BoundActions,
	StoreConfig,
	StoreInstance,
	StoreState,
} from '../types';

const namedMutator =
	(
		// storeState should be StoreState<TState>
		// but this mutator's signature doesn't match
		// the mutator's actual type of (Partial<TState>) => void
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		storeState: any,
		actionName: string,
	) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(...arg: any[]) => {
		// eslint-disable-next-line no-param-reassign
		storeState.mutator.actionName = actionName;
		return storeState.mutator(...arg);
	};

const warnings = new WeakMap();

export const bindAction = <TState, TActions extends Record<string, ActionThunk<TState, TActions>>>(
	storeState: StoreState<TState>,
	actionFn: ActionThunk<TState, TActions>,
	actionKey: string,
	config: StoreConfig,
	actions: BoundActions<TState, TActions>,
) => {
	const callThunk = (
		instance: StoreInstance<TState, TActions>,
		thunkFn: Action<TState>,
		actionName: string,
	) =>
		thunkFn(
			{
				setState: defaults.devtools
					? namedMutator(instance.storeState, actionName)
					: instance.storeState.mutator,
				getState: instance.storeState.getState,
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore - TS2353 actions() is a deprecated API
				get actions() {
					if (!warnings.has(actionFn)) {
						warnings.set(
							actionFn,
							// eslint-disable-next-line no-console
							console.warn(
								"react-sweet-state 'actions' property has been deprecated and will be removed in the next mayor. " +
									`Please check action '${actionName}' of Store '${instance.storeState.key}' and use 'dispatch' instead`,
							),
						);
					}

					return actions;
				},
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				dispatch: (tFn: Action<TState, any, any>): ReturnType<typeof tFn> =>
					callThunk(instance, tFn, `${actionName}.dispatch`),
			},
			config.props(),
		);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (...args: any[]) => callThunk({ storeState, actions }, actionFn(...args), actionKey);
};

export const bindActions = <TState, TActions extends Record<string, ActionThunk<TState, TActions>>>(
	actions: TActions,
	storeState: StoreState<TState>,
	config: StoreConfig,
	boundActions: BoundActions<TState, TActions> | null = null,
) =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(Object.keys(actions) as (keyof TActions)[]).reduce(
		(acc, k) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			acc[k] = bindAction(
				storeState,
				actions[k],
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				k as string,
				config,
				boundActions || acc,
			) as BoundActions<TState, TActions>[keyof TActions];
			return acc;
		},
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		{} as BoundActions<TState, TActions>,
	);
