import defaults from '../defaults';
import type { Middleware, StoreState } from '../types';
import update from './update';

const applyMiddleware = <TState,>(
	storeState: StoreState<TState>,
	middlewares: Iterable<Middleware> | ArrayLike<Middleware>,
) =>
	Array.from(middlewares)
		.concat(update)
		.reduceRight((next, mw) => mw(storeState)(next), defaults.mutator);

export default applyMiddleware;
