import type { Selector, StoreState } from '../types';
import { createSelector, type OutputSelector } from './create-selector';

export function createMemoizedSelector<TState, TProps, TOutput>(
	reselector: OutputSelector<TState, TProps, TOutput>,
): OutputSelector<TState, TProps, TOutput>;

export function createMemoizedSelector<TState, TSubscriberProps, TOutput>(
	selector: Selector<TState, TSubscriberProps, TOutput>,
): OutputSelector<TState, TSubscriberProps, TOutput>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createMemoizedSelector(selector: any): any {
	const isReselector =
		typeof selector.resultFunc === 'function' && Array.isArray(selector.dependencies);
	const dependencies = isReselector
		? selector.dependencies
		: // eslint-disable-next-line @typescript-eslint/no-explicit-any
			[(s: any) => s, (_: any, p: any) => p];
	const resultFunc = isReselector ? selector.resultFunc : selector;
	return createSelector(dependencies, resultFunc);
}

const cache = new WeakMap();

export function getSelectorInstance<TState, TProps, TOutput>(
	selector: Selector<TState, TProps, TOutput>,
	storeState: StoreState<TState>,
	hasProps: boolean,
) {
	if (!hasProps) {
		if (!cache.has(storeState)) {
			cache.set(storeState, new WeakMap());
		}
		const scopeSelectors = cache.get(storeState);

		if (!scopeSelectors.has(selector)) {
			scopeSelectors.set(selector, createMemoizedSelector(selector));
		}
		return scopeSelectors.get(selector);
	}
	return createMemoizedSelector(selector);
}
