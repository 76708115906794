import sentryBrowser from './sentry-browser';

// IE11 error with new sentry browser sdk - https://jdog.jira-dev.com/browse/JFP-2406
// remove when IE11 is deprecated and switch to new sentry client
export const installGlobalHandler = () =>
	__SERVER__ ? undefined : sentryBrowser.installGlobalHandler();

// IE11 error with new sentry browser sdk - https://jdog.jira-dev.com/browse/JFP-2406
// remove when IE11 is deprecated and switch to new sentry client
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const captureException = (location: string, error: Error, details: Record<any, any> = {}) =>
	__SERVER__ ? undefined : sentryBrowser.captureException(location, error, details);
