import { useMemo, useContext, useRef, useCallback, useState } from 'react';
import { useSyncExternalStore } from 'use-sync-external-store/shim';
import { Context } from '../context';
import type { StoreState, Store, ActionThunk, BoundActions, Selector } from '../types';
import { getSelectorInstance } from '../utils/create-selector.utils';

const EMPTY_SELECTOR = () => undefined;
const DEFAULT_SELECTOR = <TState,>(state: StoreState<TState>) => state;

export type HookReturnValue<TState, TActions> = [TState, TActions];
export type HookFunction<TState, TActions, TArg = void> = (
	...args: TArg extends unknown ? (TArg extends undefined ? [TArg] | [] : [TArg]) : []
) => HookReturnValue<TState, TActions>;

export type HookActionsFunction<TActions> = () => TActions;

export type HookStateFunction<TState, TArg = undefined> = (
	...args: TArg extends undefined ? [] : [TArg]
) => TState;

export function createHook<
	TState,
	TActions extends Record<string, ActionThunk<TState, TActions>>,
	TSelectedState = TState,
	THookArg = void,
>(
	store: Store<TState, TActions>,
	options?: {
		selector?: Selector<TState, THookArg, TSelectedState> | null;
	},
): HookFunction<TSelectedState, BoundActions<TState, TActions>, THookArg>;

export function createHook<TState, TActions extends Record<string, ActionThunk<TState, TActions>>>(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Store: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	{ selector }: any = {},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return function useSweetState(propsArg?: any) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const { retrieveStore }: any = useContext(Context);
		const { storeState, actions } = retrieveStore(Store);

		const hasPropsArg = propsArg !== undefined;
		const propsArgRef = useRef(propsArg);
		propsArgRef.current = propsArg;

		const stateSelector = useMemo(() => {
			if (selector) {
				return getSelectorInstance(selector, storeState, hasPropsArg);
			}

			return selector === null ? EMPTY_SELECTOR : DEFAULT_SELECTOR;
		}, [hasPropsArg, storeState]);

		const forceUpdate = useState({})[1];
		const getSnapshot = useCallback(() => {
			// parent scope has changed and notify was explicitly triggered by the container
			// we need to force the hook to re-render to listen new storeState
			if (retrieveStore(Store).storeState !== storeState) forceUpdate({});

			const state = storeState.getState();
			return stateSelector(state, propsArgRef.current);
		}, [retrieveStore, storeState, stateSelector, forceUpdate]);

		const currentState = useSyncExternalStore(storeState.subscribe, getSnapshot, getSnapshot);

		return [currentState, actions];
	};
}

export function createActionsHook<
	TState,
	TActions extends Record<string, ActionThunk<TState, TActions>>,
>(store: Store<TState, TActions>): HookActionsFunction<BoundActions<TState, TActions>>;

export function createActionsHook<
	TState,
	TActions extends Record<string, ActionThunk<TState, TActions>>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
>(Store: any): any {
	const useHook = createHook(Store, { selector: null });
	return function useSweetStateActions() {
		return useHook()[1];
	};
}

export function createStateHook<
	TState,
	TActions extends Record<string, ActionThunk<TState, TActions>>,
	TSelectedState = TState,
	THookArg = void,
>(
	store: Store<TState, TActions>,
	options?: {
		selector?: Selector<TState, THookArg, TSelectedState>;
	},
): HookStateFunction<TSelectedState, THookArg>;

export function createStateHook<
	TState,
	TActions extends Record<string, ActionThunk<TState, TActions>>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
>(Store: any, { selector }: any = {}): any {
	const useHook = createHook(Store, { selector });
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return function useSweetStateState(propsArg: any) {
		return useHook(propsArg)[0];
	};
}
