import {
	type DefaultFeatureFlagValue,
	getFeatureFlagValue as getFeatureFlagValueUsingMetaContent,
	ff as ffMetaContent,
	getMultivariateFeatureFlag as getMultivariateFeatureFlagUsingMetaContent,
	type FeatureFlagValue,
} from '@atlassian/jira-feature-flagging-using-meta';
import { isAutomaticExposureCollectionEnabled } from '@atlassian/jira-track-all-changes-sampling';
import { addFeatureFlagAccessed } from '@atlassian/ufo-feature-flags-accessed';
import { getFeatureFlagClient } from '../jira-feature-flag-client';

export function getFeatureFlagValueUsingClient<T extends FeatureFlagValue>(
	featureFlagName: string,
	defaultValue: T extends DefaultFeatureFlagValue ? T : never,
): T {
	const client = getFeatureFlagClient();
	const flag = client.getFlagEvaluation(featureFlagName, { default: defaultValue });
	if (!flag) {
		return defaultValue;
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return flag.value as T;
}

const getBooleanFeatureFlagUsingClient = (
	featureFlagName: string,
	defaultValue: false,
): boolean => {
	const client = getFeatureFlagClient();
	return client.getBooleanValue(featureFlagName, {
		default: defaultValue,
		shouldTrackExposureEvent: false,
	});
};

const getMultivariateFeatureFlagUsingClient = <T extends string>(
	featureFlagName: string,
	defaultValue: T,
	possibleValues: Readonly<T[]>,
): T => {
	const client = getFeatureFlagClient();
	// @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'T'.
	return client.getVariantValue(featureFlagName, {
		default: defaultValue,
		// @ts-expect-error - TS2322 - Type 'T[]' is not assignable to type 'string[]'.
		oneOf: possibleValues,
		shouldTrackExposureEvent: false,
	});
};

/**
 * The feature flag client can be configured to fire automatic exposure events when evaluating
 * feature flag values. For the Track All Changes project, we need all flag evaluations to go
 * through this client so that we can collect these events for all feature flags.
 * See https://hello.atlassian.net/browse/FD-18145 for more information.
 */
const isFeatureFlagClientUsedForAllEvaluations = () => isAutomaticExposureCollectionEnabled();

/**
 * @param defaultValue No other values allowed other than false go/ff-default
 */
export const ff = (featureFlagName: string, defaultValue: false = false): boolean => {
	const impl = isFeatureFlagClientUsedForAllEvaluations()
		? getBooleanFeatureFlagUsingClient
		: ffMetaContent;

	const value = impl(featureFlagName, defaultValue);

	addFeatureFlagAccessed(featureFlagName, value);

	return value;
};

/**
 * @deprecated Use `expVal` instead - see jira/docs/playbook/statsig.md for more details.
 *
 * Retrieves the feature flag value for a multivariate flag
 *
 * Multivariate flags are feature flags that allow more than 2 (e.g. boolean) variations.
 * We only support string types as variations, so `T` should be a string.
 * For better type safety, make TypeScript narrow down from `string` to concrete string literals
 * by including `as const` after the array of possible values, like in the example below.
 *
 * @example
 * ```
 * getMultivariateFeatureFlag(
 *     'flag-key',
 *     'control',
 *     ['control', 'experiment-alpha', 'experiment-beta'] as const
 *  );
 * ```
 *
 * @param featureFlagName The feature flag key
 * @param defaultValue The string value we should default to when the feature flag could not be evaluated
 * @param possibleValues All the possible variations as strings
 * @returns The feature flag value as a string
 */
export function getMultivariateFeatureFlag<T extends string>(
	featureFlagName: string,
	defaultValue: T,
	possibleValues: Readonly<T[]>,
): T {
	if (process.env.NODE_ENV !== 'production') {
		if (typeof defaultValue !== 'string') {
			throw new Error('`defaultValue` should be of type `String`');
		}
	}

	const impl = isFeatureFlagClientUsedForAllEvaluations()
		? getMultivariateFeatureFlagUsingClient
		: getMultivariateFeatureFlagUsingMetaContent;

	const value = impl(featureFlagName, defaultValue, possibleValues);

	addFeatureFlagAccessed(featureFlagName, value);

	return value;
}

/**
 * @deprecated Use `expVal` or `fg` instead - see jira/docs/playbook/statsig.md for more details.
 * @param defaultValue boolean 'true' value is not allowed go/ff-default
 */
export function getFeatureFlagValue<T extends FeatureFlagValue>(
	featureFlagName: string,
	defaultValue: T extends DefaultFeatureFlagValue ? T : never,
): T {
	const impl = isFeatureFlagClientUsedForAllEvaluations()
		? getFeatureFlagValueUsingClient
		: getFeatureFlagValueUsingMetaContent;

	const value = impl(featureFlagName, defaultValue);

	addFeatureFlagAccessed(featureFlagName, value);

	return impl(featureFlagName, defaultValue);
}
