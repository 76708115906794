import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';

export const extractFactoryFunction = <Fn extends Function>(fn: Fn) => {
	if (!(fn && typeof fn === 'function' && fn.length === 0)) {
		return fn;
	}

	try {
		const result = fn();
		return typeof result === 'function' ? result : fn;
	} catch (e) {
		return fn;
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapStateToPropsWithErrorHandling = (mapStateToProps?: any) => {
	// return non-wrapped mapStateToProps if not a function
	if (!mapStateToProps) {
		return mapStateToProps;
	}
	if (typeof mapStateToProps !== 'function') {
		return mapStateToProps;
	}

	// return wrapped mapStateToProps which catches error, reports it and throws error again
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (...props: any) => {
		try {
			return mapStateToProps(...props);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			fireErrorAnalytics({
				meta: {
					id: 'mapStateToProps',
					packageName: 'jiraReactRedux',
					teamName: 'magma',
				},
				error: e,
			});
			throw e;
		}
	};
};
