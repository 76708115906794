import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import log from '@atlassian/jira-common-util-logging/src/log';
import { FetchError, isClientFetchError, ValidationError } from '@atlassian/jira-fetch';
import { getUserLocation } from '@atlassian/jira-platform-router-utils';
import {
	fireOperationalAnalytics,
	getEvent,
	type AnalyticsAttributes,
} from '@atlassian/jira-product-analytics-bridge';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import { captureException } from './sentry';

type AnalyticsMeta = {
	id: string;
	packageName?: string;
	teamName?: string;
};

// it's a workaround since React doesn't provide native types for this
type ErrorInfo = {
	componentStack: string;
};

export type AnalyticsPayload = {
	event?: UIAnalyticsEvent;
	error?: Error;
	errorInfo?: ErrorInfo;
	meta: AnalyticsMeta;
	attributes?: AnalyticsAttributes;
	sendToPrivacyUnsafeSplunk?: boolean;
	skipSentry?: boolean;
};

// ExportForTest
export const isNetworkError = (error: Error) => {
	// @ts-expect-error - TS2339 - Property 'networkError' does not exist on type 'Error'. | TS2339 - Property 'networkError' does not exist on type 'Error'.
	const errorToCheck = error.networkError ? error.networkError : error;

	return (
		typeof errorToCheck === 'object' &&
		(errorToCheck instanceof FetchError ||
			errorToCheck instanceof ValidationError ||
			isClientFetchError(errorToCheck))
	);
};

// ExportForTest
export const getStatusCodeGroup = (error: Error) => {
	if (error instanceof FetchError) {
		const { statusCode } = error;
		if (statusCode >= 100 && statusCode < 200) return '1xx';
		if (statusCode >= 300 && statusCode < 400) return '3xx';
		if (statusCode >= 400 && statusCode < 500) return '4xx';
		if (statusCode >= 500 && statusCode < 600) return '5xx';
	}

	if (error instanceof ValidationError) {
		return '4xx';
	}

	return 'unknown';
};

const getTraceId = (error: Error) => (error instanceof FetchError ? error.traceId : undefined);

const logErrorToSentry = (
	event: undefined | UIAnalyticsEvent,
	fullAnalyticsId: string,
	error: Error,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	details: any,
) => {
	if (event?.context) {
		const fullEvent = getEvent(event);

		captureException(fullAnalyticsId, error, { ...details, ...fullEvent.payload });
	} else {
		captureException(fullAnalyticsId, error, details);
	}
};

const logToPrivacyUnsafeSplunk = (
	fullAnalyticsId: string,
	error: Error,
	errorInfo: undefined | ErrorInfo,
	meta: undefined | AnalyticsMeta,
) => {
	const errorToLog = {
		message: error.message,
		stack: error.stack,
		// Include the status code group for network errors
		statusCodeGroup: isNetworkError(error) ? getStatusCodeGroup(error) : undefined,
		// Include componentStack if present created by React's componentDidCatch
		componentStack: errorInfo ? errorInfo.componentStack : undefined,
		userLocation: getUserLocation(),
		teamName: meta?.teamName,
		packageName: meta?.packageName,
	};

	log.unsafeErrorWithCustomerData(fullAnalyticsId, error.message, errorToLog);
};

export const maskEmailIdsInMessage = (message: string) =>
	message.replaceAll(/\w+@\w+/gi, '***@***');

/**
 * Standard jira error handler.
 * If a network error is passed in a status code will be attached to the logged event.
 * Network events are not logged to sentry.
 * This can be used to filter out client side network errors from TOME SLOs
 *
 * @param errorPayload Information about the error being logged and configuration.
 * @param {AnalyticsEvent?} errorPayload.event Object describing the event that caused the error.
 * @param {Error?} errorPayload.error The error object that is being logged about, Can be sent to splunk or sentry.
 * @param {ErrorInfo?} errorPayload.errorInfo
 * @param {AnalyticsMeta} errorPayload.meta Metadata describing the name of the error to be logged, the package source of the error and the team owning the package.
 * @param {AnalyticsAttributes?} errorPayload.attributes Additional data to include in the payload being logged.
 * @param {boolean?} [errorPayload.sendToPrivacyUnsafeSplunk=false] Flag to opt to send the provided error to privacyUnsafeSplunk. Defaults to false
 * @param {boolean?} [errorPayload.skipSentry=false] Flag to opt to send the provided error to Sentry. Defaults to false
 */
const fireErrorAnalytics = async ({
	event,
	error,
	errorInfo,
	meta,
	attributes,
	sendToPrivacyUnsafeSplunk = false,
	skipSentry = false,
}: AnalyticsPayload) => {
	const networkError = error ? isNetworkError(error) : false;
	let eventAttributes: AnalyticsAttributes;
	if (networkError && error) {
		const statusCodeGroup = getStatusCodeGroup(error);
		const traceId = getTraceId(error);
		eventAttributes =
			traceId === null || traceId === undefined
				? { ...attributes, statusCodeGroup }
				: { ...attributes, statusCodeGroup, traceId };
	} else {
		eventAttributes = attributes ?? {};
	}

	const { id, packageName, teamName } = meta;

	if (__SERVER__) {
		if (error) {
			throw error;
		} else {
			const payload = event && getEvent(event).payload;
			throw new Error(
				JSON.stringify({ event: payload, errorInfo, meta, attributes: eventAttributes }),
			);
		}
	}

	const fullAnalyticsId = packageName !== undefined ? `${packageName}.${id}` : id;

	if (error) {
		if (!networkError && !skipSentry) {
			const details = omitBy(
				{
					...eventAttributes,
					packageName,
					teamName,
					errorInfo,
				},
				isEmpty,
			);
			logErrorToSentry(event, fullAnalyticsId, error, details);
		}

		// This is a stop gap measure until Sentry is fully rolled out
		if (sendToPrivacyUnsafeSplunk) {
			logToPrivacyUnsafeSplunk(fullAnalyticsId, error, errorInfo, meta);
		}

		eventAttributes = { errorMessage: error.message, ...eventAttributes };

		if (typeof eventAttributes.errorMessage === 'string') {
			eventAttributes.errorMessage = maskEmailIdsInMessage(eventAttributes.errorMessage);
		}
	}

	if (event && event?.handlers?.length > 0) {
		const attrs: AnalyticsAttributes = eventAttributes;
		fireOperationalAnalytics(event, `${fullAnalyticsId} failed`, attrs);
	} else {
		const gasV3Event = {
			action: 'failed',
			actionSubject: fullAnalyticsId,
			source: 'unknownErrorSource',
			attributes: eventAttributes,
		};

		const analyticsClient = await getAnalyticsWebClientPromise();
		analyticsClient.getInstance().sendOperationalEvent(gasV3Event);
	}
};

export type ErrorPayload = {
	event?: UIAnalyticsEvent;
	error: Error;
	id: string;
	packageName?: string;
	attributes?: AnalyticsAttributes;
	sendToPrivacyUnsafeSplunk?: boolean;
	logToSentry?: boolean;
};

/**
 * This function maintains backwards compatibility. fireErrorAnalytics is recommended to use.
 * @deprecated Use fireErrorAnalytics instead.
 */
export const trackFetchErrors = async (payload: ErrorPayload) => {
	const { event, error, id, packageName, attributes, sendToPrivacyUnsafeSplunk } = payload;
	return fireErrorAnalytics({
		event,
		error,
		attributes,
		meta: {
			packageName,
			id,
		},
		sendToPrivacyUnsafeSplunk,
	});
};

export default fireErrorAnalytics;
