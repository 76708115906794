import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { isShallowEqual } from '@atlassian/jira-platform-shallow-equal';

/* eslint-disable @typescript-eslint/no-explicit-any, func-names */
const ARITY: any = {
	0: (fn: any) =>
		function (this: any) {
			return fn.call(this);
		},
	1: (fn: any) =>
		function (this: any, arg1: any) {
			return fn.call(this, arg1);
		},
	2: (fn: any) =>
		function (this: any, arg1: any, arg2: any) {
			return fn.call(this, arg1, arg2);
		},
	3: (fn: any) =>
		function (this: any, arg1: any, arg2: any, arg3: any) {
			return fn.call(this, arg1, arg2, arg3);
		},
};

function defineArity(arity: number, fn: any) {
	if (!ARITY[arity]) {
		throw new Error(`Unsupported arity ${arity}`);
	}
	return ARITY[arity](fn);
}

const getDifference = (a: any, b: any) => {
	const diff: Record<string, any> = {};
	for (const key in a) {
		if (a[key] !== b[key]) {
			diff[key] = a[key];
		}
	}
	return diff;
};

const getTypes = (a: any) => {
	const result: Record<string, any> = {};
	// eslint-disable-next-line guard-for-in
	for (const key in a) {
		result[key] = typeof a[key];
	}
	return result;
};

const stability = (component: any, name: string, fn: any) => {
	const isDevMode = process.env.NODE_ENV !== 'production';
	let override: any = null;
	return defineArity(3, (...args: any[]) => {
		if (override) {
			return override(...args);
		}
		const v1 = fn(...args);
		if (typeof v1 === 'function') {
			override = stability(component, `${name}>`, v1);
			return override(...args);
		}
		const v2 = fn(...args);
		if (!isShallowEqual(v1, v2)) {
			const displayName = component.displayName || component.name;
			const difference = getDifference(v1, v2);
			const stabilityAttributes = {
				component,
				selector: fn,
				displayName,
				name,
				difference,
				v1,
				v2,
				differenceTypes: getTypes(difference),
				v1Types: getTypes(v1),
				v2Types: getTypes(v2),
			};

			// only log errors in dev mode
			if (isDevMode) {
				// eslint-disable-next-line no-console
				console.error(`Unstable selector ${displayName}:${name}`, stabilityAttributes);
			}

			const error = new Error(
				`Unstable selector used for ${displayName}:${name} : ${Object.keys(difference).join(',')}`,
			);

			fireErrorAnalytics({
				meta: {
					id: 'stabilityCheck',
					packageName: 'jiraReactRedux',
					teamName: 'magma',
				},
				attributes: stabilityAttributes,
				error,
			});

			// only throw error again in dev mode
			if (isDevMode) {
				throw error;
			}
		}
		return v2;
	});
};

/**
 * Wraps a component with stability checks to facilitate early detection of redux instability.
 * This enhancement is particularly useful in development environments or under specific conditions where
 * maintaining component behavior stability is critical. It encapsulates the given functionality
 * within stability checks without altering its core purpose.
 */

export const withStability = (component: any, name: string, fn: any) => {
	const isDevMode = process.env.NODE_ENV !== 'production';

	if (!isDevMode || !fn || typeof fn !== 'function') {
		return fn;
	}

	// mergeProps cannot return a factory function
	if (name === 'mergeProps') {
		return defineArity(3, stability(component, name, fn));
	}
	return defineArity(3, () => stability(component, name, fn));
};
