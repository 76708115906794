import type { FeatureFlagValue, Flags } from '../../common/types';
/**
 * Retrieves the specified feature flag's value from a collection, ensuring safe handling
 * of various data structures. Returns a default value if the flag is not present, facilitating
 * flexible feature management.
 */
export function parseFeatureFlagValue<T extends FeatureFlagValue>(
	featureFlags: Flags,
	featureFlagName: string,
	defaultValue: T,
): T {
	const flagValue = featureFlags[featureFlagName];
	if (flagValue === undefined) {
		return defaultValue;
	}
	if (typeof flagValue === 'object' && 'value' in flagValue) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return flagValue.value as T;
	}

	// This _should never happen_, but we need to validate it to remove
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	return flagValue as any;
}
