/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ComponentType, ReactNode } from 'react';
import type { ActionThunk, BoundActions, Selector, Store } from '../types';
import { createHook } from './hook';

export type RenderPropComponent<TState, TActions> = (state: TState, actions: TActions) => ReactNode;

export type SubscriberComponent<TState, TActions, TProps = undefined> = ComponentType<
	{
		children: RenderPropComponent<TState, TActions>;
	} & TProps
>;

function hasAdditionalProps(props: any) {
	for (const i in props) return true;
	return false;
}

export function createSubscriber<
	TState,
	TActions extends Record<string, ActionThunk<TState, TActions>>,
	TSelectedState = TState,
	TSubscriberProps = unknown,
>(
	store: Store<TState, TActions>,
	options?: {
		displayName?: string;
		selector?: Selector<TState, TSubscriberProps, TSelectedState> | null;
	},
): SubscriberComponent<TSelectedState, BoundActions<TState, TActions>, TSubscriberProps>;

export function createSubscriber<
	TState,
	TActions extends Record<string, ActionThunk<TState, TActions>>,
>(Store: any, { selector, displayName = '' }: any = {}): any {
	const useStore = createHook(Store, { selector });
	const Subscriber = function ({ children, ...rest }: any) {
		const api = useStore(hasAdditionalProps(rest) ? rest : undefined);
		return children(...api);
	};
	Subscriber.displayName = displayName || `Subscriber(${Store.key.split('__')[0]})`;
	return Subscriber;
}
