import type { ActionThunk, BoundActions, Store, StoreConfig, StoreInstance } from '../types';
import supports from '../utils/supported-features';
import { bindActions } from './bind-actions';
import createStoreState from './create-state';

export const GLOBAL_SCOPE = '__global__';

interface Registry {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	stores: Map<string, StoreInstance<any, any>>;

	initStore: <TState, TActions extends Record<string, ActionThunk<TState, TActions>>>(
		key: string,
		store: Store<TState, TActions>,
		config: StoreConfig,
	) => StoreInstance<TState, TActions>;

	getStore: <TState, TActions extends Record<string, ActionThunk<TState, TActions>>>(
		store: Store<TState, TActions>,
		scopeId: string,
		config: StoreConfig | null,
	) => StoreInstance<TState, TActions> | null;

	hasStore: <TState, TActions extends Record<string, ActionThunk<TState, TActions>>>(
		store: Store<TState, TActions>,
		scopeId?: string,
	) => boolean;

	deleteStore: <TState, TActions extends Record<string, ActionThunk<TState, TActions>>>(
		store: Store<TState, TActions>,
		scopeId?: string,
	) => void;
}

export class StoreRegistry implements Registry {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	stores = new Map<string, StoreInstance<any, any>>();

	constructor(private defaultScope = GLOBAL_SCOPE) {
		this.defaultScope = defaultScope;
	}

	initStore = <TState, TActions extends Record<string, ActionThunk<TState, TActions>>>(
		key: string,
		Store: Store<TState, TActions>,
		config: StoreConfig,
	): StoreInstance<TState, TActions> => {
		const { initialState, actions } = Store;

		if (Store.containedBy && !config.contained(Store)) {
			const err = new Error(
				`Store ${Store.key} should be contained by a container but it is used globally. ` +
					'While it might still work, it will likely cause unexpected behaviours.',
			);
			if (supports.scheduling()) Promise.reject(err);
			else throw err;
		}

		const storeState = createStoreState(key, initialState);
		let boundActions: BoundActions<TState, TActions>;
		const store = {
			storeState,
			// these are used only when container-less, so we generate them on-demand
			get actions() {
				if (!boundActions) boundActions = bindActions(actions, storeState, config);
				return boundActions;
			},
		};

		this.stores.set(key, store);
		return store;
	};

	hasStore = <TState, TActions extends Record<string, ActionThunk<TState, TActions>>>(
		Store: Store<TState, TActions>,
		scopeId = this.defaultScope,
	): boolean => {
		const key = this.generateKey(Store, scopeId);
		return this.stores.has(key);
	};

	getStore = <TState, TActions extends Record<string, ActionThunk<TState, TActions>>>(
		Store: Store<TState, TActions>,
		scopeId = this.defaultScope,
		config: StoreConfig | null = { props: () => ({}), contained: () => false },
	): StoreInstance<TState, TActions> | null => {
		const key = this.generateKey(Store, scopeId);
		return this.stores.get(key) || (config && this.initStore(key, Store, config));
	};

	deleteStore = <TState, TActions extends Record<string, ActionThunk<TState, TActions>>>(
		Store: Store<TState, TActions>,
		scopeId = this.defaultScope,
	) => {
		const key = this.generateKey(Store, scopeId);
		this.stores.delete(key);
	};

	private generateKey = <TState, TActions extends Record<string, ActionThunk<TState, TActions>>>(
		Store: Store<TState, TActions>,
		scopeId: string,
	) => `${Store.key}@${scopeId}`;
}

export const defaultRegistry = new StoreRegistry();
