import OriginTracing from '@atlassiansox/origin-tracing';
import { resolveAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import type {
	InitAttrs,
	AnalyticsWebClient,
} from '@atlassian/jira-product-analytics-web-client-types';
import DefaultAnalyticsWebClient from './utils/default-analytics-web-client';
import SSRAnalyticsWebClient from './utils/ssr-analytics-web-client';
/**
 * Sets up the analytics web client in the web environment, ensuring it's ready for event tracking. This includes initializing the client if it's not already set up and configuring origin tracing to enrich analytics events.
 */
export const initAnalyticsWebClient = (attrs: InitAttrs) => {
	let internalClientInitialised: boolean | undefined;
	if (!window.analyticsWebClient) {
		try {
			window.analyticsWebClient = __SERVER__
				? SSRAnalyticsWebClient
				: new DefaultAnalyticsWebClient();
			internalClientInitialised = window.analyticsWebClient.init(attrs);
			window.analyticsWebClient.setOriginTracingHandlers({
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-expect-error
				atlOrigin: (encodedOrigin) => {
					const origin = OriginTracing.fromEncoded(encodedOrigin);
					const attributes = origin.toAnalyticsAttributes();
					return {
						originTracingAttributes: {
							id: 'originId' in attributes ? attributes.originId : undefined,
							product: 'originProduct' in attributes ? attributes.originProduct : undefined,
							library: attributes.originLibrary,
						},
						taskSessionId: origin.id ?? undefined,
					};
				},
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			// purposely empty
		}
	} else {
		internalClientInitialised = !!window.analyticsWebClient.getInstance();
	}

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	resolveAnalyticsWebClientPromise(window.analyticsWebClient!);
	return internalClientInitialised;
};
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const getAnalyticsWebClient = (): AnalyticsWebClient => window.analyticsWebClient!;
