import {
	JS_EVAL_API,
	JS_EVAL_START,
	JS_EVAL_STOP,
} from '@atlassian/jira-common-constants/src/analytics-marks';
import { sanitiseAssetName } from '@atlassian/jira-platform-sanitise-asset-name';

const isDevelopment = process.env.NODE_ENV !== 'production';

const { performance, document } = window || {};
const assetToShortName = sanitiseAssetName(isDevelopment);

// defensively check for the necessary APIs, in SSR these would be missing
// OSX El Capitan and earlier: `performance.mark` is not defined [even though `performance` is present]
// https://stackoverflow.com/a/32940166/12146527
if (window && document && performance && performance.mark) {
	/**
	 * We rely on being passed the short basename of the chunk file.
	 * It must not include path or hash but should include ".js" extension.
	 */
	// @ts-expect-error - TS2740 - Type '(shortName: string) => void' is missing the following properties from type 'Window': clientInformation, closed, customElements, devicePixelRatio, and 267 more.
	window[JS_EVAL_API] = (shortName: string) => {
		const prefix = shortName.replace(/\.js$/, '.');
		const nodeList = document.querySelectorAll(`script[src*="${prefix}"]`);
		const scripts = Array.from(nodeList).filter(
			(node) => assetToShortName(node.getAttribute('src') ?? '') === shortName,
		);

		if (scripts.length !== 1) {
			// Parcel uses ESM at runtime in production, so there won't be a script tag for dynamically loaded
			// assets. We'll just trigger eval-stop here instead which is hopefully "close enough".
			if (process.env.BUNDLER === 'parcel') {
				performance.mark(`${shortName}${JS_EVAL_STOP}`);
				return;
			}
			if (isDevelopment) {
				const faultMessage =
					scripts.length < 1
						? `Script "${shortName}" not found.`
						: `More than one script "${shortName}" was found.`;

				// eslint-disable-next-line no-console
				console.warn(`Evaluate Javascript: ${faultMessage} Load event listener hasn't been added.`);
			}
			return;
		}

		const script = scripts[0];
		const onLoaded = () => {
			script.removeEventListener('load', onLoaded);
			performance.mark(`${shortName}${JS_EVAL_STOP}`);

			if (isDevelopment) {
				try {
					// not all scripts are instrumented with eval mark so measure() may error
					performance.measure(
						`_${shortName}`,
						`${shortName}${JS_EVAL_START}`,
						`${shortName}${JS_EVAL_STOP}`,
					);
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (e: any) {
					/* do nothing */
				}
			}
		};
		script.addEventListener('load', onLoaded);
	};
}
